<template>
  <b-row>
    <b-container class="m-0 p-0 p-md-1" fluid v-if="!selectedCompany">
      <!-- <b-row cols="1" class="text-left mb-3">
        <router-link
          class="text-primary no__hover"
          :to="{ name: 'CittadinoHome' }"
        >
          <b-icon icon="arrow-left-circle-fill" variant="primary"></b-icon>
          Homepage
        </router-link>
      </b-row> -->
      <b-row
        cols="1"
        cols-md="2"
        class="justify-content-between mb-2 md-mb-3"
        align-v="center"
      >
        <h4 class="text-left page-title">Il tuo Circolo di cura</h4>
        <b-col class="my-2 my-md-0 text-center text-md-right">
          <div>
            <b-button
              class="text-left my-auto"
              @click="$refs['modal-preferenze'].show()"
            >
              <b-icon icon="plus"></b-icon> aggiungi nuovo
            </b-button>
          </div>
        </b-col>
      </b-row>
      <p v-if="circle && circle.length < 1" class="puntofarmaCard py-4">
        Nessuna elemento nel tuo circolo di cura. <br /><br />
      </p>
      <b-row
        v-for="(group, label) in circle"
        :key="label"
        cols="1"
        class="max-width-100 overflow-auto py-2"
      >
        <h5 class="text-left text-secondary text-uppercase fw-600 mb-4">
          Area {{ label }}
        </h5>
        <div class="d-flex align-items-stretch flex-nowrap flex-md-wrap mt-n2">
          <CompanyCard
            v-for="(azienda, ind) in group"
            :key="ind"
            :show-heart="true"
            class="mr-3 mt-3"
            :azienda="azienda"
            v-on:company-selection="selectCompany($event, ind)"
            @toggle-preferito="togglePreferito($event)"
          />
        </div>
      </b-row>
    </b-container>
    <b-container class="m-0 p-0 p-md-1 p-lg-4" fluid v-else>
      <b-row cols="1" class="text-left mb-3">
        <span class="text-primary point" @click.prevent="backToView">
          <b-icon icon="arrow-left-circle-fill" variant="primary"></b-icon>
          Il mio circolo di cura
        </span>
      </b-row>
      <b-row cols="1" class="my-4" v-if="selectedCompany">
        <h4 class="text-prim-grad-1 text-left pb-1 ml-3">
          {{ selectedCompany.denominazione }}
        </h4>

        <b-col class="d-flex flex-wrap mt-2 puntofarmaCard p-4">
          <b-row cols="2" class="text-left col-12">
            <b-col class="col-12 col-md-6">
              <h6 class="fw-600 my-2">Sede</h6>
              <p class="mb-0">
                {{ selectedCompany.indirizzo }} {{ selectedCompany.cap }},
                <span class="text-capitalize"
                  >{{ selectedCompany.frazione }}
                  {{ selectedCompany.comune }}</span
                >
              </p>
              <p class="mb-0 text-capitalize">
                {{ selectedCompany.provincia }} {{ selectedCompany.regione }}
              </p>
              <h6 class="fw-600 my-2">Contatti</h6>
              <p class="">
                <span class="text-lowercase">email: </span>
                {{ selectedCompany.email }}<br />
                <span class="text-lowercase">telefono: </span>
                {{ selectedCompany.telefono }}
              </p>
            </b-col>
            <b-col class="col-12 col-md-6">
              <h6 class="fw-600">Apertura</h6>
              <p
                v-if="
                  selectedCompany.apertura &&
                  selectedCompany.apertura.length > 0
                "
              >
                <span
                  v-for="(gg, index) in selectedCompany.apertura"
                  :key="index"
                >
                  <span>{{ Object.keys(gg)[0] }}: </span>
                  <span v-if="gg[Object.keys(gg)[0]] == 'chiuso'">Chiuso</span>
                  <span v-else>
                    {{ gg[Object.keys(gg)[0]]["mattina"] }}
                    {{ gg[Object.keys(gg)[0]]["pomeriggio"] }}</span
                  >
                  <br />
                </span>
              </p>
              <p v-else>Non inseriti</p>
            </b-col>
          </b-row>
          <b-row cols="1" class="text-left col-12">
            <div class="my-2">
              <router-link
                tag="button"
                class="btn btn-primary py-1"
                :to="{
                  name: 'appuntamenti',
                  params: { section: 'nuovo' },
                  query: { cindex: circleIndex }
                }"
              >
                <b-icon icon="bag-fill" class="mr-1"></b-icon
                ><span class="fs-5 text-white">Prenota</span>
              </router-link>
            </div>
            <div
              class="my-2"
              v-if="
                !user.farmacia_preferita ||
                selectedCompany.hashid !== user.farmacia_preferita.hashid
              "
            >
              <b-button
                @click="setAsPreferito(selectedCompany)"
                variant="secondary text-white"
                class="py-1"
              >
                <b-icon icon="heart-fill" class="mr-1"></b-icon>Imposta come
                preferito
              </b-button>
            </div>
            <div class="my-2">
              <b-button
                variant="danger text-white"
                class="py-1"
                @click="removeRelationship"
              >
                <b-icon icon="x-circle-fill" class="mr-1"></b-icon>
                <!-- <b-icon icon="bookmark-x-fill" class="mr-1"></b-icon> -->
                Rimuovi dal mio circolo di cura
              </b-button>
            </div>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
    <b-modal
      size="xl"
      centered
      ref="modal-preferenze"
      title="Aggiungi al tuo circolo di cura"
      hide-footer
    >
      <SearchCompany
        v-on:new-selection="updatePreferenze($event)"
        class="px-3 py-3"
      />
    </b-modal>
  </b-row>
</template>
<script>
import { supportService } from "@/_services"
import { mapState, mapActions } from "vuex"
import CompanyCard from "@/components/prenotazioni/CompanyCard.vue"
import SearchCompany from "@/components/home/SearchCompany.vue"
export default {
  name: "CircoloCura",
  components: {
    CompanyCard,
    SearchCompany
  },
  data() {
    return {
      selectedCompany: null,
      circleIndex: null,
      circle: null
    }
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert
    }),
    ...mapState("utente", ["status", "user"])
  },
  // watch:{
  //   showMainView:function(val){
  //     val === null;
  //   }
  // },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info"
    }),
    ...mapActions("utente", ["me"]),
    selectCompany(company, index) {
      this.selectedCompany = company
      this.circleIndex = index
    },
    backToView() {
      this.selectedCompany = null
      this.circleIndex = null
    },
    removeRelationship() {
      var form = {
        company: this.selectedCompany.hashid
      }
      // console.log("removing selected");
      var self = this
      supportService
        .removeRelationshipWithCompany(form)
        .then(function (response) {
          self.fetchData()
          self.backToView()
          window.scrollTo(0, 0)
          self.successAlert("Elemento rimosso correttamente!")
        })
        .catch(function (error) {
          window.scrollTo(0, 0)
          self.errorAlert(
            "non è stato possibile rimuovere l'elemento selezionato"
          )
          self.backToView()
        })
    },
    setAsPreferito(azienda = null) {
      const company = azienda ? azienda : this.selectedCompany
      var form = {
        company: company.hashid,
        preferito: true
      }
      var self = this
      supportService
        .updateRelationshipWithCompany(form)
        .then(function (response) {
          self.fetchData()
          self.backToView()
          window.scrollTo(0, 0)
          self.successAlert("preferenze aggiornate correttamente!")
        })
        .catch(function (error) {
          window.scrollTo(0, 0)
          self.errorAlert("non è stato possibile aggiornare le preferenze")
          self.backToView()
        })
    },
    togglePreferito(azienda) {
      if (
        !this.user.farmacia_preferita ||
        azienda.hashid !== this.user.farmacia_preferita.hashid
      ) {
        this.setAsPreferito(azienda)
      }
    },
    updatePreferenze(nuovaPreferita) {
      this.fetchData()
      this.$refs["modal-preferenze"].hide()
    },
    fetchData() {
      this.me()
      var self = this
      supportService
        .getUserCircoloCura()
        .then(function (response) {
          self.circle = response.data.data
        })
        .catch(function (error) {
          window.scrollTo(0, 0)
          self.errorAlert("non è stato possibile recuperare le informazioni")
        })
    }
  },
  created() {},
  mounted() {
    this.fetchData()
  }
}
</script>
